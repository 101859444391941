import React, { useEffect, useRef } from 'react';
import '../public/output.css'; // 富文本 样式
import "@/styles/globals.css";
import * as Sentry from '@sentry/nextjs';
import Custom500 from './500';
import Head from 'next/head'
import type { AppProps } from 'next/app';
import withTheme from '@/theme/index';
import { App } from 'antd';
import '../public/assets/iconfont/iconfont.css';
import type { NextPageWithLayout } from '@/types/App';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

function WebsiteApp({ Component, pageProps: { navigationData = [], websiteInfo = {}, ...restPageProps }, router }: AppPropsWithLayout) {
  const previousUrlRef = useRef<string | null>(null);
  // 监听路由变化
  useEffect(() => {
    const handleRouteChange = () => {
      // 记录上个页面的地址
      previousUrlRef.current = router.asPath || null;
    };

    // 在客户端路由变化时记录上个页面地址
    router.events.on('routeChangeComplete', handleRouteChange);

    // 清理函数，在组件卸载时移除监听器
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // 获取页面布局 getLayout 如果页面没有定义则使用默认布局 即：页面本身
  const getLayout = Component.getLayout || ((Page, layoutProps, pageProps) => <Page {...pageProps} {...layoutProps} />);

  return (
    <Sentry.ErrorBoundary fallback={<Custom500 />}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover" />
      </Head>
      {withTheme(
        <App style={{ height: '100%' }}>
          {getLayout(Component, { navData: navigationData, websiteInfo }, restPageProps)}
        </App>
      )}
    </Sentry.ErrorBoundary>
  );
}

export default WebsiteApp;